import Vue from "vue";
import App from "./App.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueRouter from "vue-router";
Vue.use(VueRouter);

Vue.config.productionTip = false;
Vue.component("vue-phone-number-input", VuePhoneNumberInput);

const routes = [
    {
        path: "/sto/tehosmotr63",
        component: () => import("./pages/sto/tehosmotr63"),
        name: "tehosmotr",
        meta: { title: "Техосмотр" },
    },
    {
        path: "/sto/request",
        component: () => import("./pages/sto/request"),
        name: "request",
        meta: { title: "Автосервис / Расчет стоимости" },
    },
    {
        path: "/sto/callback",
        component: () => import("./pages/sto/callback"),
        name: "request",
        meta: { title: "Автосервис / Обратный звонок" },
    },
    {
        path: "/salon/check-offer",
        component: () => import("./pages/salon/check-offer"),
        name: "oferta",
        meta: { title: "Договор-оферта на осмотр ТС" },
    },
    {
        path: "/salon/commission",
        component: () => import("./pages/salon/commission"),
        name: "commission",
        meta: { title: "Быстрая оценка авто" },
    },
    {
        path: "/salon/antizhulik",
        component: () => import("./pages/salon/antizhulik"),
        name: "antizhulik",
        meta: { title: "Антижулик" },
    },
    {
        path: "/salon/antizhulik/get",
        component: () => import("./pages/salon/antizhulik/get"),
        name: "antizhulik-get",
        meta: { title: "Антижулик" },
    },
];

// 3. Создаём экземпляр маршрутизатора и передаём маршруты в опции `routes`
// Вы можете передавать и дополнительные опции, но пока не будем усложнять.
const router = new VueRouter({
    mode: "history",
    routes, // сокращённая запись для `routes: routes`,
});
const DEFAULT_TITLE = "Арлан Авто";
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title
            ? to.meta.title + " | «" + DEFAULT_TITLE + "»"
            : DEFAULT_TITLE;
    });
});

new Vue({
    render: (h) => h(App),
    router,
}).$mount("#app");
