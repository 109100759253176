<template>
    <div id="app" :class="`page-${$route.name}`">
        <Header></Header>
        <div class="container page-container">
            <router-view></router-view>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
    name: "App",
    components: {
        Footer,
        Header,
    },
    created() {
        console.log(process.env.NODE_ENV);
    },
};
</script>

<style lang="less">
@import url("./assets/less/vars.less");
@import url("./assets/less/fonts.less");
@import url("./assets/less/style.less");
</style>
