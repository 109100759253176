<template>
    <div class="footer">
        <span class="color-text3">© Автокомплекс «Арлан-Авто», {{ date }}</span
        ><br />
        <a href="https://yandex.ru/legal/smartcaptcha_notice/" class="color-text3 captcha-text "
            >Защищено Yandex SmartCaptcha</a
        >
    </div>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {
            date: new Date().getFullYear(),
        };
    },
};
</script>
<style lang="less">
@import url("../assets/less/vars.less");
.footer {
    background: @bg001;
    padding: 25px;
    text-align: center;
    margin-top: auto;
    @media @sm {
        display: grid;
        gap: 10px;
    }
    a {
        margin-left: 16px;
        color: @text001;
        text-decoration: none;
        &:hover {
            color: @blue;
        }
        @media @sm {
            margin-left: 0;
        }
    }
}
.color-text3 {
    color: @text003;
}
.captcha-text {
    font-size: 12px;
    margin-top: 8px;
    display: inline-block;
}
</style>
